import React from 'react';
import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import 'prismjs/themes/prism.css';

import { Layout } from '../components/layout';
import { Icon } from '../components/Icon';
import { Comments } from '../components/Comments';
import allTopics from '../../contents/topics.json';

const getTwitterShareLink = (siteUrl, slug, title, twitter) =>
  `https://twitter.com/intent/tweet?text=${encodeURI(title)}&url=${siteUrl}${slug}&via=${twitter}`;

const getFacebookShareLink = (siteUrl, slug) =>
  `https://www.facebook.com/sharer/sharer.php?u=${siteUrl}${slug}`;

const Article = ({ pageContext: { slug }, data: { site: { siteMetadata }, markdownRemark: post } }) => (
  <Layout title={post.frontmatter.title} description={post.excerpt}>
    <Helmet>
      <link rel="canonical" href={siteMetadata.siteUrl + slug} />
      <meta name="author" content={post.frontmatter.author} />
      <meta property="og:url" content={siteMetadata.siteUrl + slug} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={post.frontmatter.title} />
      <meta property="og:description" content={post.excerpt} />
      <meta property="og:image" content={siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fixed.src} />
      <meta property="og:image:secure_url" content={siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fixed.src} />
      <meta property="og:image:type" content={`image/${post.frontmatter.image.extension === 'jpg' ? 'jpeg' : post.frontmatter.image.extension}`} />
      <meta property="og:image:width" content={post.frontmatter.image.childImageSharp.fixed.width} />
      <meta property="og:image:height" content={post.frontmatter.image.childImageSharp.fixed.height} />
      <meta property="article:author" content={post.frontmatter.author} />
      <meta property="article:published_time" content={post.frontmatter.datePublished} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={siteMetadata.social.twitter} />
      <meta name="twitter:creator" content={siteMetadata.social.twitter} />
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'Article',
          mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': siteMetadata.siteUrl + slug
          },
          headline: post.frontmatter.title,
          description: post.excerpt,
          image: siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fixed.src,
          datePublished: post.frontmatter.datePublished,
          dateModified: post.frontmatter.dateModified,
          author: {
            '@type': 'Person',
            'name': post.frontmatter.author
          },
          publisher: {
            "@type": 'Organization',
            name: siteMetadata.title,
            url: siteMetadata.siteUrl,
            logo: {
              '@type': 'ImageObject',
              url: siteMetadata.siteUrl + '/icons/icon-512x512.png'
            }
          }
        }, undefined, 2)}
      </script>
    </Helmet>
    <section className="section">
      <div className="container">
        <h1 className="title">{post.frontmatter.title}</h1>
        <hr />
        <div className="content has-text-justified" dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr />
        <div className="columns has-text-grey">
          <div className="column is-narrow">
            <Icon name="date_range" className="small" /> <span title="Publish Date" style={{ verticalAlign: 'middle' }}>{new Date(post.frontmatter.datePublished).toDateString()}</span>
          </div>
          <div className="column">
            <Icon name="subject" className="small" /> <span title="Topics" style={{ verticalAlign: 'middle' }}>{post.frontmatter.topics.map((t, i) => <span key={t}>{i === 0 ? '' : ', '}<Link to={`/topics/${t}`}>{allTopics[t].title}</Link></span>)}</span>
          </div>
          <div className="column is-narrow">
            <Icon name="share" className="small" /> <span style={{ verticalAlign: 'middle' }}>Share this article on &nbsp;<a href={getTwitterShareLink(siteMetadata.siteUrl, slug, post.frontmatter.title, siteMetadata.social.twitter)} target="_blank" rel="noopener noreferrer"><Icon name="twitter" className="medium" /></a> &nbsp;&nbsp;<a href={getFacebookShareLink(siteMetadata.siteUrl, slug)} target="_blank" rel="noopener noreferrer"><Icon name="facebook" className="medium" /></a></span>
          </div>
        </div>
        <Comments path={slug} />
      </div>
    </section>
  </Layout>
);

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        social {
          twitter
        }
      }
    }
    markdownRemark(fields: {slug: {eq: $slug } }) {
      html
      frontmatter {
        title
        author
        datePublished
        dateModified
        topics
        image {
          extension
          childImageSharp {
            fixed(width: 1200, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      excerpt(pruneLength: 500)
  }
}
`

export default Article;
