import React from 'react';

import { Icon } from './Icon';
import { formatDate, formatDateTime } from '../utils';
import RippleSvg from '../images/ripple.svg';

const commentsApi = async (func, params) => {
  const apiUrl = process.env.NODE_ENV === 'production' ?
    'https://mkbnatvqd8.execute-api.us-east-1.amazonaws.com/prod/main' : 
    'https://jyvmqcb537.execute-api.us-east-1.amazonaws.com/dev/main';
  const resp = await fetch(`${apiUrl}/${func}`, {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(params)
  });
  if (!resp.ok) {
    const contentType = resp.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      const data = await resp.json();
      throw new Error(data.error);
    } else {
      const data = await resp.text();
      throw new Error(data);
    }
  }
  return await resp.json();
}

export class Comments extends React.Component {
  blankComment = {
    comment: '',
    name: '',
    email: ''
  };
  state = {
    status: 'loading',
    scrolled: false,
    loadingError: '',
    postingError: '',
    comments: [],
    newComment: {
      ...this.blankComment
    }
  }
  componentDidMount() {
    window.addEventListener('scroll', this.isScrolledIntoComments);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.isScrolledIntoComments);
  }
  isScrolledIntoComments = () => {
    if (this.state.scrolled) return;
    const el = document.getElementById('commentsTitle');
    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;
    // Only completely visible elements return true:
    const isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:
    // const isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    if (isVisible) {
      this.setState({ scrolled: true });
      this.loadComments();
      window.removeEventListener('scroll', this.isScrolledIntoComments);
    }
  }
  loadComments = async () => {
    console.log('Loading comments');
    const { path } = this.props;
    try {
      this.setState({ status: 'loading', loadingError: '' });
      const comments = await commentsApi('get-comments', { path });
      this.setState({ status: '', comments });
    } catch (err) {
      console.log(err);
      this.setState({ status: '', loadingError: err.message || err.toString() });
    }
  }
  handleFieldChange = field => event => {
    this.setState({ newComment: { ...this.state.newComment, [field]: event.target.value } });
  }
  postComment = async (event) => {
    event.preventDefault();
    const { path } = this.props;
    const { newComment } = this.state;
    this.setState({ status: 'posting', postingError: '' });
    try {
      await commentsApi('add-comment', { ...newComment, path });
      this.setState({ status: 'posted', newComment: { ...this.blankComment } });
    } catch (err) {
      this.setState({ status: '', postingError: err.message || err.toString() });
    }
  }
  render() {
    const { status, loadingError, postingError, comments, newComment } = this.state;
    return (
      <>
        <h4 className="title" id="commentsTitle">Comments</h4>
        {status === 'loading' ?
          <img src={RippleSvg} alt="Loading" title="Loading" /> :
          <div>
            {loadingError && <div className="notification margin-top">
              <p>{loadingError}</p>
              <button className="button is-rounded is-outlined is-dark margin-top-small" onClick={this.loadComments}>Reload</button>
            </div>}
            {comments.map(c => (
              <div key={c.id} className="box">
                <p className="has-text-justified">{c.comment}</p>
                <div className="comments-meta columns is-mobile is-size-7 has-text-grey">
                  <div className="column is-narrow">
                    <Icon name="person" className="small" /> <span title="Author" style={{ verticalAlign: 'middle' }}>{c.name}</span>
                  </div>
                  <div className="column is-narrow">
                    <Icon name="date_range" className="small" /> <span title={formatDateTime(c.createdAt)} style={{ verticalAlign: 'middle' }}>{formatDate(c.createdAt)}</span>
                  </div>
                </div>
              </div>
            ))}
            <div className="margin-top">
              <form onSubmit={this.postComment}>
                <h2 className="subtitle">Add a comment</h2>
                <fieldset disabled={status === 'posting'} style={{ border: 'none' }}>
                  <div className="field">
                    <div className="control">
                      <textarea className="textarea" placeholder="Your remarks" required maxLength="10000" value={newComment.comment} onChange={this.handleFieldChange('comment')}></textarea>
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input className="input" type="text" placeholder="Your good name" required maxLength="255" value={newComment.name} onChange={this.handleFieldChange('name')} />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input className="input" type="email" placeholder="Your email (will remain private)" required maxLength="255" value={newComment.email} onChange={this.handleFieldChange('email')} />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <button className={`button is-rounded is-outlined is-dark ${status === 'posting' ? 'is-loading' : ''}`}>Submit</button>
                    </div>
                  </div>
                </fieldset>
              </form>
              <div className="columns">
                <div className="column is-narrow">
                  {status === 'posted' && <div className="notification margin-top">
                    <button className="delete" onClick={() => this.setState({ status: '' })}></button>
                    <p>You comment has been posted successfully, it will be displayed in comments after approval.</p>
                  </div>}
                  {postingError && <div className="notification margin-top">
                    <button className="delete" onClick={() => this.setState({ postingError: '' })}></button>
                    <p>{postingError}</p>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}
